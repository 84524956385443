var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":"","active":_vm.carregando}}),_c('v-card-title',[_c('span',{staticClass:"text-truncate"},[_vm._v("Modelo atual - Meta atingida por filial")]),_c('v-spacer'),_c('v-img',{staticClass:"mx-auto",attrs:{"max-width":"220","src":require("../../assets/icons/logo_dark_min.png")}})],1),(_vm.carregando)?_c('v-card-text',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","height":"300","type":"image, image"}})],1):_c('v-card-text',[(_vm.dashboard.vendafilial)?_c('GraficoBarDashboard',{attrs:{"dataSeries":[
                                {
                                    name: 'Atingido (%)',
                                    data: _vm.dashboard.vendafilial.map(function (v) {return v.peratingimento.toFixed(2)})
                                } ],"categories":_vm.dashboard.vendafilial.map(function (v) {return v.descrfilial.replaceAll('(Loja 28)', '').replaceAll('Loja', '').replaceAll('( 31)', '')}),"horizontal":false,"money":false,"porcentagem":true,"height":"300","distributed":true,"dataLabels":{ position : 'bottom', orientation : 'vertical', offsetY: 20 },"color":['#002D56', '#F2B90F'],"mobile":{
											breakpoint: 700,
											options: {
												chart: { height: 600, },
												plotOptions: {
													bar: { 
														dataLabels: { enabled: true, orientation: 'horizontal' },
														horizontal: true
													}
												},
												yaxis: { show: true, forceNiceScale: true },
												xaxis: { position: 'top', labels: { trim: false } },
												dataLabels: { offsetY: -2, offsetX: -20 }
											}
										}}}):_c('span',[_vm._v("Nenhum registro encontrado")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":"","active":_vm.carregando}}),_c('v-card-title',[_c('span',{staticClass:"text-truncate"},[_vm._v("Modelo 1 - Meta atingida por filial")]),_c('v-spacer'),_c('v-img',{staticClass:"mx-auto",attrs:{"max-width":"220","src":require("../../assets/icons/logo_dark_min.png")}})],1),(_vm.carregando)?_c('v-card-text',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","height":"300","type":"image, image"}})],1):_c('v-card-text',[(_vm.dashboard.vendafilial)?_c('GraficoBarDashboard',{attrs:{"dataSeries":[
                                {
                                    name: 'Atingido (%)',
                                    data: _vm.dashboard.vendafilial.sort(function (a, b) { return b.peratingimento - a.peratingimento; }).map(function (v) {return v.peratingimento.toFixed(2)})
                                } ],"categories":_vm.dashboard.vendafilial.sort(function (a, b) { return b.peratingimento - a.peratingimento; }).map(function (v) {return v.descrfilial.replaceAll('(Loja 28)', '').replaceAll('Loja', '').replaceAll('( 31)', '')}),"horizontal":false,"money":false,"porcentagem":true,"height":"300","distributed":true,"dataLabels":{ position : 'bottom', orientation : 'vertical', offsetY: 20 },"color":_vm.corMod1(),"mobile":{
											breakpoint: 700,
											options: {
												chart: { height: 600, },
												plotOptions: {
													bar: { 
														dataLabels: { enabled: true, orientation: 'horizontal' },
														horizontal: true
													}
												},
												yaxis: { show: true, forceNiceScale: true },
												xaxis: { position: 'top', labels: { trim: false } },
												dataLabels: { offsetY: -2, offsetX: -20 }
											}
										}}}):_c('span',[_vm._v("Nenhum registro encontrado")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":"","active":_vm.carregando}}),_c('v-card-title',[_c('span',{staticClass:"text-truncate"},[_vm._v("Modelo 2 - Meta atingida por filial")]),_c('v-spacer'),_c('v-img',{staticClass:"mx-auto",attrs:{"max-width":"220","src":require("../../assets/icons/logo_dark_min.png")}})],1),(_vm.carregando)?_c('v-card-text',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","height":"300","type":"image, image"}})],1):_c('v-card-text',[(_vm.dashboard.vendafilial)?_c('GraficoBarDashboard',{attrs:{"dataSeries":[
                                {
                                    name: 'Atingido (%)',
                                    data: _vm.dashboard.vendafilial.sort(function (a, b) { return b.peratingimento - a.peratingimento; }).map(function (v) {return v.peratingimento.toFixed(2)})
                                } ],"categories":_vm.dashboard.vendafilial.sort(function (a, b) { return b.peratingimento - a.peratingimento; }).map(function (v) {return v.descrfilial.replaceAll('(Loja 28)', '').replaceAll('Loja', '').replaceAll('( 31)', '')}),"horizontal":false,"money":false,"porcentagem":true,"height":"300","distributed":true,"dataLabels":{ position : 'bottom', orientation : 'vertical', offsetY: 20 },"color":_vm.corMod2(),"mobile":{
											breakpoint: 700,
											options: {
												chart: { height: 600, },
												plotOptions: {
													bar: { 
														dataLabels: { enabled: true, orientation: 'horizontal' },
														horizontal: true
													}
												},
												yaxis: { show: true, forceNiceScale: true },
												xaxis: { position: 'top', labels: { trim: false } },
												dataLabels: { offsetY: -2, offsetX: -20 }
											}
										}}}):_c('span',[_vm._v("Nenhum registro encontrado")])],1)],1)],1),_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"12"}},[_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":"","active":_vm.carregando}}),_c('v-card-title',[_c('span',{staticClass:"text-truncate"},[_vm._v("Modelo 3 - Meta atingida por filial")]),_c('v-spacer'),_c('v-img',{staticClass:"mx-auto",attrs:{"max-width":"220","src":require("../../assets/icons/logo_dark_min.png")}})],1),(_vm.carregando)?_c('v-card-text',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","height":"300","type":"image, image"}})],1):_c('v-card-text',[(_vm.dashboard.vendafilial)?_c('GraficoBarDashboard',{attrs:{"dataSeries":[
                                {
                                    name: 'Atingido (%)',
                                    data: _vm.dashboard.vendafilial.sort(function (a, b) { return b.peratingimento - a.peratingimento; }).map(function (v) {return v.peratingimento.toFixed(2)})
                                } ],"categories":_vm.dashboard.vendafilial.sort(function (a, b) { return b.peratingimento - a.peratingimento; }).map(function (v) {return v.descrfilial.replaceAll('(Loja 28)', '').replaceAll('Loja', '').replaceAll('( 31)', '')}),"horizontal":false,"money":false,"porcentagem":true,"height":"300","distributed":true,"dataLabels":{ position : 'bottom', orientation : 'vertical', offsetY: 20 },"color":_vm.corMod1(),"mobile":{
											breakpoint: 700,
											options: {
												chart: { height: 600, },
												plotOptions: {
													bar: { 
														dataLabels: { enabled: true, orientation: 'horizontal' },
														horizontal: true
													}
												},
												yaxis: { show: true, forceNiceScale: true },
												xaxis: { position: 'top', labels: { trim: false } },
												dataLabels: { offsetY: -2, offsetX: -20 }
											}
										}}}):_c('span',[_vm._v("Nenhum registro encontrado")])],1)],1)],1),_c('v-col',{staticClass:"d-block d-md-none",attrs:{"cols":"12"}},[_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":"","active":_vm.carregando}}),_c('v-card-title',[_c('span',{staticClass:"text-truncate"},[_vm._v("Modelo 3 - Meta atingida por filial")]),_c('v-spacer'),_c('v-img',{staticClass:"mx-auto",attrs:{"max-width":"220","src":require("../../assets/icons/logo_dark_min.png")}})],1),(_vm.carregando)?_c('v-card-text',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","height":"300","type":"image, image"}})],1):_c('v-card-text',[(_vm.dashboard.vendafilial)?[_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',_vm._l((_vm.dashboard.vendafilial),function(u,i){return _c('tr',{key:i},[_c('td',{staticClass:"text-truncate",attrs:{"width":"30%"}},[_vm._v(_vm._s(u.descrfilial))]),_c('td',[_c('v-progress-linear',{staticClass:"white--text",attrs:{"rounded":"","color":_vm.shadeColor(_vm.corSemafaro(u.peratingimento), 100 - u.peratingimento),"height":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(value.toFixed(2))+"%")])]}}],null,true),model:{value:(u.peratingimento),callback:function ($$v) {_vm.$set(u, "peratingimento", $$v)},expression:"u.peratingimento"}})],1)])}),0)])]:_c('span',[_vm._v("Nenhum registro encontrado")])],2)],1)],1),_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"12"}},[_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":"","active":_vm.carregando}}),_c('v-card-title',[_c('span',{staticClass:"text-truncate"},[_vm._v("Modelo 4 - Meta atingida por filial")]),_c('v-spacer'),_c('v-img',{staticClass:"mx-auto",attrs:{"max-width":"220","src":require("../../assets/icons/logo_dark_min.png")}})],1),(_vm.carregando)?_c('v-card-text',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","height":"300","type":"image, image"}})],1):_c('v-card-text',[(_vm.dashboard.vendafilial)?_c('GraficoBarDashboard',{attrs:{"dataSeries":[
                                {
                                    name: 'Atingido (%)',
                                    data: _vm.dashboard.vendafilial.sort(function (a, b) { return b.peratingimento - a.peratingimento; }).map(function (v) {return v.peratingimento.toFixed(2)})
                                } ],"categories":_vm.dashboard.vendafilial.sort(function (a, b) { return b.peratingimento - a.peratingimento; }).map(function (v) {return v.descrfilial.replaceAll('(Loja 28)', '').replaceAll('Loja', '').replaceAll('( 31)', '')}),"horizontal":false,"money":false,"porcentagem":true,"height":"300","distributed":true,"dataLabels":{ position : 'bottom', orientation : 'vertical', offsetY: 20 },"color":_vm.corMod2(),"mobile":{
											breakpoint: 700,
											options: {
												chart: { height: 600, },
												plotOptions: {
													bar: { 
														dataLabels: { enabled: true, orientation: 'horizontal' },
														horizontal: true
													}
												},
												yaxis: { show: true, forceNiceScale: true },
												xaxis: { position: 'top', labels: { trim: false } },
												dataLabels: { offsetY: -2, offsetX: -20 }
											}
										}}}):_c('span',[_vm._v("Nenhum registro encontrado")])],1)],1)],1),_c('v-col',{staticClass:"d-block d-md-none",attrs:{"cols":"12"}},[_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":"","active":_vm.carregando}}),_c('v-card-title',[_c('span',{staticClass:"text-truncate"},[_vm._v("Modelo 4 - Meta atingida por filial")]),_c('v-spacer'),_c('v-img',{staticClass:"mx-auto",attrs:{"max-width":"220","src":require("../../assets/icons/logo_dark_min.png")}})],1),(_vm.carregando)?_c('v-card-text',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","height":"300","type":"image, image"}})],1):_c('v-card-text',[(_vm.dashboard.vendafilial)?[_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',_vm._l((_vm.dashboard.vendafilial),function(u,i){return _c('tr',{key:i},[_c('td',{staticClass:"text-truncate",attrs:{"width":"30%"}},[_vm._v(_vm._s(u.descrfilial))]),_c('td',[_c('v-progress-linear',{staticClass:"white--text",attrs:{"rounded":"","color":_vm.shadeColor('#002D56', 100 - u.peratingimento),"height":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(value.toFixed(2))+"%")])]}}],null,true),model:{value:(u.peratingimento),callback:function ($$v) {_vm.$set(u, "peratingimento", $$v)},expression:"u.peratingimento"}})],1)])}),0)])]:_c('span',[_vm._v("Nenhum registro encontrado")])],2)],1)],1),_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"12"}},[_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":"","active":_vm.carregando}}),_c('v-card-title',[_c('span',{staticClass:"text-truncate"},[_vm._v("Modelo 5 - Meta atingida por filial")]),_c('v-spacer'),_c('v-img',{staticClass:"mx-auto",attrs:{"max-width":"220","src":require("../../assets/icons/logo_dark_min.png")}})],1),(_vm.carregando)?_c('v-card-text',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","height":"300","type":"image, image"}})],1):_c('v-card-text',[(_vm.dashboard.vendafilial)?_c('GraficoBarDashboard',{attrs:{"dataSeries":[
                                {
                                    name: 'Atingido (%)',
                                    data: _vm.dashboard.vendafilial.sort(function (a, b) { return b.peratingimento - a.peratingimento; }).map(function (v) {return v.peratingimento.toFixed(2)})
                                } ],"categories":_vm.dashboard.vendafilial.sort(function (a, b) { return b.peratingimento - a.peratingimento; }).map(function (v) {return v.descrfilial.replaceAll('(Loja 28)', '').replaceAll('Loja', '').replaceAll('( 31)', '')}),"horizontal":false,"money":false,"porcentagem":true,"height":"300","distributed":true,"dataLabels":{ position : 'bottom', orientation : 'vertical', offsetY: 20 },"color":_vm.corMod1(),"mobile":{
											breakpoint: 700,
											options: {
												chart: { height: 600, },
												plotOptions: {
													bar: { 
														dataLabels: { enabled: true, orientation: 'horizontal' },
														horizontal: true
													}
												},
												yaxis: { show: true, forceNiceScale: true },
												xaxis: { position: 'top', labels: { trim: false } },
												dataLabels: { offsetY: -2, offsetX: -20 }
											}
										}}}):_c('span',[_vm._v("Nenhum registro encontrado")])],1)],1)],1),_c('v-col',{staticClass:"d-block d-md-none",attrs:{"cols":"12"}},[_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":"","active":_vm.carregando}}),_c('v-card-title',[_c('span',{staticClass:"text-truncate"},[_vm._v("Modelo 5 - Meta atingida por filial")]),_c('v-spacer'),_c('v-img',{staticClass:"mx-auto",attrs:{"max-width":"220","src":require("../../assets/icons/logo_dark_min.png")}})],1),(_vm.carregando)?_c('v-card-text',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","height":"300","type":"image, image"}})],1):_c('v-card-text',[(_vm.dashboard.vendafilial)?[_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',_vm._l((_vm.dashboard.vendafilial),function(u,i){return _c('tr',{key:i},[_c('td',{staticClass:"text-truncate",attrs:{"width":"30%"}},[_vm._v(_vm._s(u.descrfilial))]),_c('td',{staticClass:"text-end"},[_vm._v(" "+_vm._s(u.peratingimento.toFixed(2))+" % "),_c('v-progress-circular',{staticClass:"ml-2",attrs:{"rotate":360,"size":25,"width":4,"value":u.peratingimento,"color":_vm.shadeColor(_vm.corSemafaro(u.peratingimento), 100 - u.peratingimento)}})],1)])}),0)])]:_c('span',[_vm._v("Nenhum registro encontrado")])],2)],1)],1),_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"12"}},[_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":"","active":_vm.carregando}}),_c('v-card-title',[_c('span',{staticClass:"text-truncate"},[_vm._v("Modelo 6 - Meta atingida por filial")]),_c('v-spacer'),_c('v-img',{staticClass:"mx-auto",attrs:{"max-width":"220","src":require("../../assets/icons/logo_dark_min.png")}})],1),(_vm.carregando)?_c('v-card-text',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","height":"300","type":"image, image"}})],1):_c('v-card-text',[(_vm.dashboard.vendafilial)?_c('GraficoBarDashboard',{attrs:{"dataSeries":[
                                {
                                    name: 'Atingido (%)',
                                    data: _vm.dashboard.vendafilial.sort(function (a, b) { return b.peratingimento - a.peratingimento; }).map(function (v) {return v.peratingimento.toFixed(2)})
                                } ],"categories":_vm.dashboard.vendafilial.sort(function (a, b) { return b.peratingimento - a.peratingimento; }).map(function (v) {return v.descrfilial.replaceAll('(Loja 28)', '').replaceAll('Loja', '').replaceAll('( 31)', '')}),"horizontal":false,"money":false,"porcentagem":true,"height":"300","distributed":true,"dataLabels":{ position : 'bottom', orientation : 'vertical', offsetY: 20 },"color":_vm.corMod2(),"mobile":{
											breakpoint: 700,
											options: {
												chart: { height: 600, },
												plotOptions: {
													bar: { 
														dataLabels: { enabled: true, orientation: 'horizontal' },
														horizontal: true
													}
												},
												yaxis: { show: true, forceNiceScale: true },
												xaxis: { position: 'top', labels: { trim: false } },
												dataLabels: { offsetY: -2, offsetX: -20 }
											}
										}}}):_c('span',[_vm._v("Nenhum registro encontrado")])],1)],1)],1),_c('v-col',{staticClass:"d-block d-md-none",attrs:{"cols":"12"}},[_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":"","active":_vm.carregando}}),_c('v-card-title',[_c('span',{staticClass:"text-truncate"},[_vm._v("Modelo 6 - Meta atingida por filial")]),_c('v-spacer'),_c('v-img',{staticClass:"mx-auto",attrs:{"max-width":"220","src":require("../../assets/icons/logo_dark_min.png")}})],1),(_vm.carregando)?_c('v-card-text',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","height":"300","type":"image, image"}})],1):_c('v-card-text',[(_vm.dashboard.vendafilial)?[_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',_vm._l((_vm.dashboard.vendafilial),function(u,i){return _c('tr',{key:i},[_c('td',{staticClass:"text-truncate",attrs:{"width":"30%"}},[_vm._v(_vm._s(u.descrfilial))]),_c('td',{staticClass:"text-end"},[_vm._v(" "+_vm._s(u.peratingimento.toFixed(2))+" % "),_c('v-progress-circular',{staticClass:"ml-2",attrs:{"rotate":360,"size":25,"width":4,"value":u.peratingimento,"color":_vm.shadeColor('#002D56', 100 - u.peratingimento)}})],1)])}),0)])]:_c('span',[_vm._v("Nenhum registro encontrado")])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }