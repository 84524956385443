<template>
	<v-row>
		<v-col cols="12">
			<v-card>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title>
					<span class="text-truncate">Modelo atual - Meta atingida por filial</span>
					<v-spacer />
					<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
				</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<GraficoBarDashboard
						v-if="dashboard.vendafilial"
						:dataSeries="[
                                {
                                    name: 'Atingido (%)',
                                    data: dashboard.vendafilial.map((v) => {return v.peratingimento.toFixed(2)})
                                },                            
                            ]"
						:categories="dashboard.vendafilial.map((v) => {return v.descrfilial.replaceAll('(Loja 28)', '').replaceAll('Loja', '').replaceAll('( 31)', '')})"
						:horizontal="false"
						:money="false"
						:porcentagem="true"
						height="300"
						:distributed="true"
						:dataLabels="{ position : 'bottom', orientation : 'vertical', offsetY: 20 }"
						:color="['#002D56', '#F2B90F']"
						:mobile="{
											breakpoint: 700,
											options: {
												chart: { height: 600, },
												plotOptions: {
													bar: { 
														dataLabels: { enabled: true, orientation: 'horizontal' },
														horizontal: true
													}
												},
												yaxis: { show: true, forceNiceScale: true },
												xaxis: { position: 'top', labels: { trim: false } },
												dataLabels: { offsetY: -2, offsetX: -20 }
											}
										}"
					/>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>

		<v-col cols="12">
			<v-card>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title>
					<span class="text-truncate">Modelo 1 - Meta atingida por filial</span>
					<v-spacer />
					<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
				</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<GraficoBarDashboard
						v-if="dashboard.vendafilial"
						:dataSeries="[
                                {
                                    name: 'Atingido (%)',
                                    data: dashboard.vendafilial.sort((a, b) => b.peratingimento - a.peratingimento).map((v) => {return v.peratingimento.toFixed(2)})
                                },                            
                            ]"
						:categories="dashboard.vendafilial.sort((a, b) => b.peratingimento - a.peratingimento).map((v) => {return v.descrfilial.replaceAll('(Loja 28)', '').replaceAll('Loja', '').replaceAll('( 31)', '')})"
						:horizontal="false"
						:money="false"
						:porcentagem="true"
						height="300"
						:distributed="true"
						:dataLabels="{ position : 'bottom', orientation : 'vertical', offsetY: 20 }"
						:color="corMod1()"
						:mobile="{
											breakpoint: 700,
											options: {
												chart: { height: 600, },
												plotOptions: {
													bar: { 
														dataLabels: { enabled: true, orientation: 'horizontal' },
														horizontal: true
													}
												},
												yaxis: { show: true, forceNiceScale: true },
												xaxis: { position: 'top', labels: { trim: false } },
												dataLabels: { offsetY: -2, offsetX: -20 }
											}
										}"
					/>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>

		<v-col cols="12">
			<v-card>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title>
					<span class="text-truncate">Modelo 2 - Meta atingida por filial</span>
					<v-spacer />
					<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
				</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<GraficoBarDashboard
						v-if="dashboard.vendafilial"
						:dataSeries="[
                                {
                                    name: 'Atingido (%)',
                                    data: dashboard.vendafilial.sort((a, b) => b.peratingimento - a.peratingimento).map((v) => {return v.peratingimento.toFixed(2)})
                                },                            
                            ]"
						:categories="dashboard.vendafilial.sort((a, b) => b.peratingimento - a.peratingimento).map((v) => {return v.descrfilial.replaceAll('(Loja 28)', '').replaceAll('Loja', '').replaceAll('( 31)', '')})"
						:horizontal="false"
						:money="false"
						:porcentagem="true"
						height="300"
						:distributed="true"
						:dataLabels="{ position : 'bottom', orientation : 'vertical', offsetY: 20 }"
						:color="corMod2()"
						:mobile="{
											breakpoint: 700,
											options: {
												chart: { height: 600, },
												plotOptions: {
													bar: { 
														dataLabels: { enabled: true, orientation: 'horizontal' },
														horizontal: true
													}
												},
												yaxis: { show: true, forceNiceScale: true },
												xaxis: { position: 'top', labels: { trim: false } },
												dataLabels: { offsetY: -2, offsetX: -20 }
											}
										}"
					/>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>
		<!-- Modelo com progress bar linear -->
		<v-col cols="12" class="d-none d-md-block">
			<v-card>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title>
					<span class="text-truncate">Modelo 3 - Meta atingida por filial</span>
					<v-spacer />
					<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
				</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<GraficoBarDashboard
						v-if="dashboard.vendafilial"
						:dataSeries="[
                                {
                                    name: 'Atingido (%)',
                                    data: dashboard.vendafilial.sort((a, b) => b.peratingimento - a.peratingimento).map((v) => {return v.peratingimento.toFixed(2)})
                                },                            
                            ]"
						:categories="dashboard.vendafilial.sort((a, b) => b.peratingimento - a.peratingimento).map((v) => {return v.descrfilial.replaceAll('(Loja 28)', '').replaceAll('Loja', '').replaceAll('( 31)', '')})"
						:horizontal="false"
						:money="false"
						:porcentagem="true"
						height="300"
						:distributed="true"
						:dataLabels="{ position : 'bottom', orientation : 'vertical', offsetY: 20 }"
						:color="corMod1()"
						:mobile="{
											breakpoint: 700,
											options: {
												chart: { height: 600, },
												plotOptions: {
													bar: { 
														dataLabels: { enabled: true, orientation: 'horizontal' },
														horizontal: true
													}
												},
												yaxis: { show: true, forceNiceScale: true },
												xaxis: { position: 'top', labels: { trim: false } },
												dataLabels: { offsetY: -2, offsetX: -20 }
											}
										}"
					/>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" class="d-block d-md-none">
			<v-card>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title>
					<span class="text-truncate">Modelo 3 - Meta atingida por filial</span>
					<v-spacer />
					<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
				</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<template v-if="dashboard.vendafilial">
						<v-simple-table dense>
							<tbody>
								<tr v-for="(u,i) in dashboard.vendafilial" :key="i">
									<td width="30%" class="text-truncate">{{u.descrfilial}}</td>
									<td>
										<v-progress-linear
											rounded
											v-model="u.peratingimento"
											:color="shadeColor(corSemafaro(u.peratingimento), 100 - u.peratingimento)"
											class="white--text"
											height="20"
										>
											<template v-slot:default="{ value }">
												<strong>{{ value.toFixed(2) }}%</strong>
											</template>
										</v-progress-linear>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
					</template>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>

		<v-col cols="12" class="d-none d-md-block">
			<v-card>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title>
					<span class="text-truncate">Modelo 4 - Meta atingida por filial</span>
					<v-spacer />
					<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
				</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<GraficoBarDashboard
						v-if="dashboard.vendafilial"
						:dataSeries="[
                                {
                                    name: 'Atingido (%)',
                                    data: dashboard.vendafilial.sort((a, b) => b.peratingimento - a.peratingimento).map((v) => {return v.peratingimento.toFixed(2)})
                                },                            
                            ]"
						:categories="dashboard.vendafilial.sort((a, b) => b.peratingimento - a.peratingimento).map((v) => {return v.descrfilial.replaceAll('(Loja 28)', '').replaceAll('Loja', '').replaceAll('( 31)', '')})"
						:horizontal="false"
						:money="false"
						:porcentagem="true"
						height="300"
						:distributed="true"
						:dataLabels="{ position : 'bottom', orientation : 'vertical', offsetY: 20 }"
						:color="corMod2()"
						:mobile="{
											breakpoint: 700,
											options: {
												chart: { height: 600, },
												plotOptions: {
													bar: { 
														dataLabels: { enabled: true, orientation: 'horizontal' },
														horizontal: true
													}
												},
												yaxis: { show: true, forceNiceScale: true },
												xaxis: { position: 'top', labels: { trim: false } },
												dataLabels: { offsetY: -2, offsetX: -20 }
											}
										}"
					/>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" class="d-block d-md-none">
			<v-card>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title>
					<span class="text-truncate">Modelo 4 - Meta atingida por filial</span>
					<v-spacer />
					<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
				</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<template v-if="dashboard.vendafilial">
						<v-simple-table dense>
							<tbody>
								<tr v-for="(u,i) in dashboard.vendafilial" :key="i">
									<td width="30%" class="text-truncate">{{u.descrfilial}}</td>
									<td>
										<v-progress-linear
											rounded
											v-model="u.peratingimento"
											:color="shadeColor('#002D56', 100 - u.peratingimento)"
											class="white--text"
											height="20"
										>
											<template v-slot:default="{ value }">
												<strong>{{ value.toFixed(2) }}%</strong>
											</template>
										</v-progress-linear>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
					</template>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>
		<!-- Modelo com progress bar circular -->
		<v-col cols="12" class="d-none d-md-block">
			<v-card>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title>
					<span class="text-truncate">Modelo 5 - Meta atingida por filial</span>
					<v-spacer />
					<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
				</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<GraficoBarDashboard
						v-if="dashboard.vendafilial"
						:dataSeries="[
                                {
                                    name: 'Atingido (%)',
                                    data: dashboard.vendafilial.sort((a, b) => b.peratingimento - a.peratingimento).map((v) => {return v.peratingimento.toFixed(2)})
                                },                            
                            ]"
						:categories="dashboard.vendafilial.sort((a, b) => b.peratingimento - a.peratingimento).map((v) => {return v.descrfilial.replaceAll('(Loja 28)', '').replaceAll('Loja', '').replaceAll('( 31)', '')})"
						:horizontal="false"
						:money="false"
						:porcentagem="true"
						height="300"
						:distributed="true"
						:dataLabels="{ position : 'bottom', orientation : 'vertical', offsetY: 20 }"
						:color="corMod1()"
						:mobile="{
											breakpoint: 700,
											options: {
												chart: { height: 600, },
												plotOptions: {
													bar: { 
														dataLabels: { enabled: true, orientation: 'horizontal' },
														horizontal: true
													}
												},
												yaxis: { show: true, forceNiceScale: true },
												xaxis: { position: 'top', labels: { trim: false } },
												dataLabels: { offsetY: -2, offsetX: -20 }
											}
										}"
					/>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" class="d-block d-md-none">
			<v-card>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title>
					<span class="text-truncate">Modelo 5 - Meta atingida por filial</span>
					<v-spacer />
					<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
				</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<template v-if="dashboard.vendafilial">
						<v-simple-table dense>
							<tbody>
								<tr v-for="(u,i) in dashboard.vendafilial" :key="i">
									<td width="30%" class="text-truncate">{{u.descrfilial}}</td>
									<td class="text-end">
										{{ u.peratingimento.toFixed(2) }} %
										<v-progress-circular
											class="ml-2"
											:rotate="360"
											:size="25"
											:width="4"
											:value="u.peratingimento"
											:color="shadeColor(corSemafaro(u.peratingimento), 100 - u.peratingimento)"
										></v-progress-circular>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
					</template>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>

		<v-col cols="12" class="d-none d-md-block">
			<v-card>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title>
					<span class="text-truncate">Modelo 6 - Meta atingida por filial</span>
					<v-spacer />
					<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
				</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<GraficoBarDashboard
						v-if="dashboard.vendafilial"
						:dataSeries="[
                                {
                                    name: 'Atingido (%)',
                                    data: dashboard.vendafilial.sort((a, b) => b.peratingimento - a.peratingimento).map((v) => {return v.peratingimento.toFixed(2)})
                                },                            
                            ]"
						:categories="dashboard.vendafilial.sort((a, b) => b.peratingimento - a.peratingimento).map((v) => {return v.descrfilial.replaceAll('(Loja 28)', '').replaceAll('Loja', '').replaceAll('( 31)', '')})"
						:horizontal="false"
						:money="false"
						:porcentagem="true"
						height="300"
						:distributed="true"
						:dataLabels="{ position : 'bottom', orientation : 'vertical', offsetY: 20 }"
						:color="corMod2()"
						:mobile="{
											breakpoint: 700,
											options: {
												chart: { height: 600, },
												plotOptions: {
													bar: { 
														dataLabels: { enabled: true, orientation: 'horizontal' },
														horizontal: true
													}
												},
												yaxis: { show: true, forceNiceScale: true },
												xaxis: { position: 'top', labels: { trim: false } },
												dataLabels: { offsetY: -2, offsetX: -20 }
											}
										}"
					/>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" class="d-block d-md-none">
			<v-card>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title>
					<span class="text-truncate">Modelo 6 - Meta atingida por filial</span>
					<v-spacer />
					<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
				</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<template v-if="dashboard.vendafilial">
						<v-simple-table dense>
							<tbody>
								<tr v-for="(u,i) in dashboard.vendafilial" :key="i">
									<td width="30%" class="text-truncate">{{u.descrfilial}}</td>
									<td class="text-end">
										{{ u.peratingimento.toFixed(2) }} %
										<v-progress-circular
											class="ml-2"
											:rotate="360"
											:size="25"
											:width="4"
											:value="u.peratingimento"
											:color="shadeColor('#002D56', 100 - u.peratingimento)"
										></v-progress-circular>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
					</template>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import GraficoBarDashboard from "../Widgets/GraficoBarDashboard.vue";

export default {
	name: "Prototipo",
	components: {
		GraficoBarDashboard,
	},
	data: () => ({
		carregando: false,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
	},
	methods: {
		get() {
			this.dashboard = {};
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dashboard/principal`, {
					dtini: "2022-11-01",
					dtfim: "2022-11-30",
					idregional: null,
					idfilial: null,
				})
				.then((res) => {
					this.dashboard = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		corMod1() {
			let cores = [];
			cores = this.dashboard.vendafilial
				.sort((a, b) => b.peratingimento - a.peratingimento)
				.map((v) => {
					let cor;
					cor = this.corSemafaro(v.peratingimento);
					return this.shadeColor(cor, 100 - v.peratingimento);
				});
			return cores;
		},
		corMod2() {
			let cores = [];
			cores = this.dashboard.vendafilial
				.sort((a, b) => b.peratingimento - a.peratingimento)
				.map((v) => {
					let cor = "#002D56";
					return this.shadeColor(cor, 100 - v.peratingimento);
				});
			return cores;
		},
		corSemafaro(valor) {
			let cor;
			if (valor >= 90) cor = "#002D56";
			if (valor < 90) cor = "#F2B90F";
			if (valor < 70) cor = "#951015";
			return cor;
		},
		shadeColor(color, percent) {
			var R = parseInt(color.substring(1, 3), 16);
			var G = parseInt(color.substring(3, 5), 16);
			var B = parseInt(color.substring(5, 7), 16);
			R = parseInt((R * (100 + percent)) / 100);
			G = parseInt((G * (100 + percent)) / 100);
			B = parseInt((B * (100 + percent)) / 100);
			R = R < 255 ? R : 255;
			G = G < 255 ? G : 255;
			B = B < 255 ? B : 255;
			var RR =
				R.toString(16).length == 1
					? "0" + R.toString(16)
					: R.toString(16);
			var GG =
				G.toString(16).length == 1
					? "0" + G.toString(16)
					: G.toString(16);
			var BB =
				B.toString(16).length == 1
					? "0" + B.toString(16)
					: B.toString(16);
			return "#" + RR + GG + BB;
		},
	},
	created() {
		this.get();
	},
};
</script>